import { Component } from 'vue-property-decorator'
import { NegotiationView } from '@/components/forms/view/NegotiationView'
import dayjs from 'dayjs'
import { parseToNumber } from '@/utils/general'

@Component
export class PurchaseView extends NegotiationView {
  statusPurchaseInProcess = null
  statusPurchaseToConfirm = null
  statusDocument = null
  purchaseOrderDocumentType = null
  statusPaymentOrder = null
  processExpenseBeneficiary = null
  paymentStatus = {
    pending: null,
  }

  purchaseOrderStatus = {
    toUpdate: null,
    approved: null,
    active: null,
    toConfirm: null,
  }

  async mounted () {
    this.paymentStatus.pending = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'payment' } } }, { status: { name: { _eq: 'pending' } } }] },
    })
    this.statusPurchaseInProcess = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'purchase_order' } } }, { status: { name: { _eq: 'active' } } }] },
    })

    this.statusDocument = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'document' } } }, { status: { name: { _eq: 'pending' } } }] },
    })

    this.processExpenseBeneficiary = await this.fetchData({
      query: { name: 'find', model: 'ProcessExpense' },
      filter: { expense: { name: { _eq: 'auto_payment' } } },
    })

    this.statusPaymentOrder = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'payment_order' } } }, { status: { name: { _eq: 'pending' } } }] },
    })

    this.purchaseOrderStatus.approved = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'purchase_order' } } }, { status: { name: { _eq: 'approved' } } }] },
    })

    this.purchaseOrderStatus.toUpdate = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'purchase_order' } } }, { status: { name: { _eq: 'to_update' } } }] },
    })

    this.purchaseOrderStatus.active = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'purchase_order' } } }, { status: { name: { _eq: 'active' } } }] },
    })

    this.purchaseOrderStatus.toConfirm = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'purchase_order' } } }, { status: { name: { _eq: 'to_confirm' } } }] },
    })
  }

  async updatePurchase (viewData) {
    const { purchaseOrder } = viewData

    await this.updatePurchaseOrder(viewData)
    await this.updateInterveners(viewData)
    await this.insertPurchaseOrderUpdateFile(viewData, purchaseOrder.id)
    await this.insertPaymentRecipient(viewData)
  }

  async insertPaymentRecipient (viewData) {
    const { formData: { expenses }, paymentOrder, payments: fetchPayment } = viewData

    const payments = expenses.payments
      .filter(payment => !payment?.id)

    if (payments.length) {
      const items = paymentOrder?.[0]?.items
      const expenseMap = this.calculateExpenses(expenses)

      const orderItems = await Promise.all([...expenseMap.entries()].map(async ([name, totalAmount]) => {
        const processExpense = await this.fetchData({
          query: { name: 'find', model: 'ProcessExpense' },
          filter: { expense: { name: { _eq: name } } },
        })

        const orderItemFind = items?.find(item => item.name === name)
        const filteredPayments = orderItemFind?.paymentRecipients?.flatMap(item => item.payments)
        const totalItem = filteredPayments
          ?.filter(pay => !fetchPayment?.map(pay => pay?.id)
            ?.includes(pay?.id))
          ?.reduce((acc, curr) => acc + curr.amount, 0) || 0

        const fields = {
          id: orderItemFind?.id,
          id_payment_order: paymentOrder?.[0]?.id,
          amount: totalAmount + totalItem,
          id_process_expense: processExpense?.[0]?.id,
        }
        if (!fields.id) {
          delete fields.id
        } else {
          delete fields.id_payment_order
          delete fields.id_process_expense
        }

        const orderItem = await this.pushData({
          model: 'PaymentOrderItem',
          fields,
        })

        return {
          name,
          id: orderItem.id,
        }
      }))

      const recipientMap = new Map()

      const recipients = paymentOrder?.[0]?.items?.flatMap(item =>
        item.recipients?.map(recipient => ({
          key: `${recipient.paymentOrder.id}_${recipient.paymentOrderItem.id}_${recipient.person.id}`,
          id: recipient.id,
        })) || []
      )

      for (const item of expenses.payments.filter(payment => !payment?.id)) {
        const orderItem = orderItems.find(oi => oi.name === item.type)

        const recipientKey = `${paymentOrder?.[0].id}_${orderItem.id}_${item?.person?.id}`
        if (!recipients.find(recip => recip.key === recipientKey) && !recipientMap.has(recipientKey)) {
          const recipient = await this.pushData({
            model: 'PaymentRecipient',
            fields: {
              id_payment_order: paymentOrder?.[0]?.id,
              id_payment_order_item: orderItem.id,
              id_person: item?.person?.id,
            },
          })
          recipientMap.set(recipientKey, recipient.id)
        } else if (!recipientMap.has(recipientKey)) {
          recipientMap.set(recipientKey, recipients.find(recip => recip.key === recipientKey).id)
        }
      }

      await Promise.all(expenses.payments.filter(payment => !payment?.id).map(async item => {
        const orderItem = orderItems.find(oi => oi.name === item.type)
        const recipientKey = `${paymentOrder?.[0]?.id}_${orderItem.id}_${item?.person?.id}`
        const recipientId = recipientMap.get(recipientKey)

        await this.insertPaymentToOrder(viewData, { id: recipientId }, item)
      }))
    }
  }

  async updateInterveners (viewData) {
    const { document, formData: { signers }, idProcess } = viewData
    const { interveners } = document[0]

    const { deleteInterveners, insertInterveners } = this.getIntervenersAndSigners(interveners, signers)
    if (deleteInterveners.length) {
      await Promise.all(deleteInterveners.map(async id => {
        await this.removeData({
          model: 'Intervener',
          fields: { id },
        })
      }))
    }

    if (insertInterveners.length) {
      this.purchaseOrderDocumentType = await this.fetchData({
        query: { name: 'find', model: 'DocumentType' },
        filter: { _and: [{ name: { _eq: 'sale_purchase_contract' } }, { process: { id: { _eq: idProcess } } }] },
      })
      const newInterveners = this.isSellerOrRepresentative(viewData, this.purchaseOrderDocumentType[0].fields, insertInterveners, true)

      const { id } = document[0]

      await Promise.all(newInterveners.map(async intervener => {
        const fields = {
          id_document: id,
          ...intervener,
        }
        await this.pushData({
          model: 'Intervener',
          fields,
        })
      }))
    }
  }

  getIntervenersAndSigners (interveners, signers) {
    const representative = interveners.filter(intervener => intervener.field.name === 'sale_representative')

    const signersIdSet = new Set(signers.map(signer => signer.id))
    const intervenersPersonIdSet = new Set(representative.map(intervener => intervener.person.id))

    const toDelete = representative.filter(intervener => !signersIdSet.has(intervener.person.id)).map(intervener => intervener.id)
    const toInsert = signers.filter(signer => !intervenersPersonIdSet.has(signer.id))

    return {
      deleteInterveners: toDelete,
      insertInterveners: toInsert,
    }
  }

  async updatePurchaseOrder (viewData) {
    const { formData: { price, documentType, agreedTransfer, acquisitionType, consignment }, purchaseOrder } = viewData
    const isPriceDifferent = parseToNumber(price) !== parseToNumber(purchaseOrder.agreedAmount)
    const isDocumentDifferent = documentType?.id !== purchaseOrder.supportDocumentType?.id
    const isAgreedTransferDifferent = agreedTransfer?.id !== purchaseOrder.transferType?.id

    if ((isPriceDifferent || isDocumentDifferent || isAgreedTransferDifferent) && !consignment?.length) {
      const fields = {
        id: purchaseOrder.id,
        id_acquisition_type: acquisitionType.id,
        agreed_amount: price,
        id_support_document_type: documentType?.id,
        id_transfer_type: agreedTransfer?.id,
      }

      if (!isPriceDifferent) {
        delete fields.agreed_amount
      }
      if (!isDocumentDifferent) {
        delete fields.id_support_document_type
      }
      if (!isAgreedTransferDifferent) {
        delete fields.id_transfer_type
      }

      await this.pushData({
        model: 'PurchaseOrder',
        fields,
      })

      await this.pushData({
        model: 'PaymentOrder',
        fields: {
          id: purchaseOrder.paymentOrder.id,
          amount: price,
        },
      })
      await this.pushData({
        model: 'PurchaseOrder',
        fields: { id: purchaseOrder.id, id_process_status: this.statusPurchaseInProcess?.[0]?.id },
      })
    } else if (consignment?.length) {
      const fields = {
        id: purchaseOrder.id,
        id_process_status: this.purchaseOrderStatus.toConfirm[0].id,
      }
      await this.pushData({
        model: 'PurchaseOrder',
        fields,
      })
    }
  }

  async findAssociatedFiles (idProcess, id, name) {
    if (!id) return {}

    const report = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: id } },
          { parameter: { process: { id: { _eq: idProcess } } } },
          { parameter: { name: { _eq: 'legal_report' } } }],
      },
      force: true,
    })

    const document = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: id } },
          { parameter: { process: { id: { _eq: idProcess } } } },
          { parameter: { name: { _eq: name } } }],
      },
      force: true,
    })

    const notarized = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: id } },
          { parameter: { process: { id: { _eq: idProcess } } } },
          { parameter: { name: { _eq: 'notarized_authorization' } } }],
      },
      force: true,
    })

    const consignmentFile = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: id } }, { parameter: { process: { id: { _eq: idProcess } } } }, { parameter: { name: { _eq: 'consignment_contract' } } }] },
      force: true,
    })

    return {
      report,
      document,
      notarized,
      consignmentFile,
    }
  }

  async createPurchase (viewData) {
    const { formData: { price, documentType, agreedTransfer, acquisitionType }, negotiation, idProcess } = viewData

    const idStatus = acquisitionType.name === 'consignment'
      ? this.statusPurchaseInProcess?.[0]?.id : this.purchaseOrderStatus.toConfirm?.[0]?.id

    const fields = {
      agreed_amount: price,
      id_acquisition_type: acquisitionType.id,
      id_negotiation: negotiation.id,
      id_process_status: idStatus,
      id_support_document_type: documentType?.id,
      id_transfer_type: agreedTransfer?.id,
    }

    const purchase = await this.pushData({
      model: 'PurchaseOrder',
      fields,
    })

    this.purchaseOrderDocumentType = await this.fetchData({
      query: { name: 'find', model: 'DocumentType' },
      filter: { _and: [{ name: { _eq: 'sale_purchase_contract' } }, { process: { id: { _eq: idProcess } } }] },
    })

    const purchaseOrderDocumentTypeConsignment = await this.fetchData({
      query: { name: 'find', model: 'DocumentType' },
      filter: { _and: [{ name: { _eq: 'consignment_contract' } }, { process: { id: { _eq: idProcess } } }] },
    })

    if (acquisitionType.name === 'consignment') {
      await this.insertDocument(viewData, purchase.id, purchaseOrderDocumentTypeConsignment[0])
    }
    await this.insertDocument(viewData, purchase.id, this.purchaseOrderDocumentType[0])
    await this.insertPurchaseOrderUpdateFile(viewData, purchase.id)
    await this.insertPaymentOrder(viewData, purchase.id)

    await this.pushData({
      model: 'Negotiation',
      fields: {
        id: negotiation.id,
        id_process_status: this.statusNegotiation.closed?.[0]?.id,
        id_closing_reason: this.closingReason.success?.[0]?.id,
      },
    })
  }

  async insertPurchaseOrderUpdateFile (viewData, id) {
    const {
      idProcess,
      formData: {
        legalReport,
        buyFile,
        proofPayment,
        notarizedAuthorization,
        linkLegalReport,
        linkContract,
        consignment,
      },
      fields: {
        legalReport: legalReportField,
        buyFile: buyFileField,
        proofPayment: proofPaymentField,
        notarizedAuthorization: notarizedAuthorizationField,
        consignment: consignmentField,
      },
    } = viewData

    if (legalReport?.length) {
      await this.handleFileType(legalReport, legalReportField, idProcess, id)
      const fileId = this.isArrayFiles(legalReport) ? legalReport[0]?.id : legalReport?.[0]?.file?.id
      await this.insertFileInfo({ id: fileId, source_link: linkLegalReport })
    }
    if (buyFile?.length) {
      await this.handleFileType(buyFile, buyFileField, idProcess, id)

      if (buyFileField?.properties?.name === 'digital_contract') {
        const fileId = this.isArrayFiles(buyFile) ? buyFile[0]?.id : buyFile?.[0]?.file?.id
        await this.insertFileInfo({ id: fileId, source_link: linkContract })
      }
    }

    if (proofPayment?.length) {
      await this.handleFileType(proofPayment, proofPaymentField, idProcess, id)
    }

    if (notarizedAuthorization?.length) {
      await this.handleFileType(notarizedAuthorization, notarizedAuthorizationField, idProcess, id)
    }

    if (consignment?.length) {
      await this.handleFileType(consignment, consignmentField, idProcess, id)
    }
  }

  async insertDocument (viewData, id, documentType) {
    const { statusDocument } = this

    const fields = {
      id_process_status: statusDocument?.[0]?.id,
      id_document_type: documentType?.id,
      id_process_record: id,
      date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      interveners: {
        data: [...this.isSellerOrRepresentative(viewData, documentType.fields)],
      },
    }

    await this.pushData({
      model: 'Document',
      fields,
    })
  }

  processSigner (signer, isSeller, fields) {
    let field
    if (isSeller) {
      field = {
        id_person: isSeller?.id,
        uid: isSeller.uid,
        business_name: isSeller.fullName,
        id_field: fields.find(field => field.name === 'seller')?.id,
      }
    } else {
      field = {
        id_person: signer.id,
        business_name: signer.fullName,
        uid: signer.uid,
        id_field: fields.find(field => field.name === 'sale_representative')?.id,
      }
    }

    return field
  }

  isSellerOrRepresentative (viewData, fields, insertSigners = [], update = false) {
    const { formData: { owners, signers } } = viewData

    const getSigners = insertSigners?.length ? insertSigners : signers

    const newSigners = getSigners?.map(signer => this.processSigner(signer, null, fields)) || []
    const oldOwners = owners?.map(owner => this.processSigner(null, owner, fields)) || []

    if (update) {
      return [...newSigners].filter(Boolean)
    }

    return [...newSigners, ...oldOwners].filter(Boolean)
  }

  calculateExpenses (expenses) {
    const expenseMap = new Map()

    if (!expenses?.payments?.length) return expenseMap

    const payments = expenses?.payments?.filter(payment => !payment?.id)

    payments.forEach(item => {
      const itemName = item.type
      const amount = parseInt(item?.amount) || 0

      if (expenseMap.has(itemName)) {
        expenseMap.set(itemName, expenseMap.get(itemName) + amount)
      } else {
        expenseMap.set(itemName, amount)
      }
    })

    return expenseMap
  }

  async insertPaymentOrder (viewData, id) {
    const { formData: { expenses, price }, negotiation, idProcess } = viewData

    const fields = {
      id_process: idProcess,
      id_deal: negotiation.inspection.appraisal.deal.id,
      id_process_record: id,
      id_process_status: this.statusPaymentOrder?.[0]?.id,
      id_person: negotiation.inspection.appraisal.deal.lead.client.id,
      amount: price,
    }

    const order = await this.pushData({
      model: 'PaymentOrder',
      fields,
    })

    const expenseMap = this.calculateExpenses(expenses)

    const orderItems = await Promise.all([...expenseMap.entries()].map(async ([name, totalAmount]) => {
      const processExpense = await this.fetchData({
        query: { name: 'find', model: 'ProcessExpense' },
        filter: { expense: { name: { _eq: name } } },
      })

      const orderItem = await this.pushData({
        model: 'PaymentOrderItem',
        fields: {
          id_payment_order: order.id,
          amount: totalAmount,
          id_process_expense: processExpense?.[0]?.id,
        },
      })

      return {
        name,
        id: orderItem.id,
      }
    }))

    const recipientMap = new Map()

    for (const item of expenses.payments) {
      const orderItem = orderItems.find(oi => oi.name === item.type)
      const recipientKey = `${order.id}_${orderItem.id}_${item?.person?.id}`

      if (!recipientMap.has(recipientKey)) {
        const recipient = await this.pushData({
          model: 'PaymentRecipient',
          fields: {
            id_payment_order: order.id,
            id_payment_order_item: orderItem.id,
            id_person: item?.person?.id,
          },
        })
        recipientMap.set(recipientKey, recipient.id)
      }
    }

    await Promise.all(expenses.payments.map(async item => {
      const orderItem = orderItems.find(oi => oi.name === item.type)
      const recipientKey = `${order.id}_${orderItem.id}_${item?.person?.id}`
      const recipientId = recipientMap.get(recipientKey)

      await this.insertPaymentToOrder(viewData, { id: recipientId }, item)
    }))
  }

  async insertPaymentToOrder (viewData, recipient, item) {
    const { negotiation } = viewData
    const { paymentStatus: { pending } } = this

    const type = await this.fetchData({
      query: { name: 'find', model: 'PaymentType' },
      filter: { name: { _eq: 'electronic_transfer' } },
    })

    const fields = {
      id_process_status: pending[0].id,
      id_payment_type: item?.paymentType?.id || type?.[0]?.id,
      id_payment_recipient: recipient.id,
      amount: item?.amount,
      comment: item?.paymentRecipient?.payments[0]?.comment,
      id_deal: negotiation?.inspection.appraisal.deal.id,
      id_recipient_account: item?.account?.id || item?.recipients?.[0]?.payments?.recipientAccount?.id || null,
    }
    const newPayment = await this.pushData({
      model: 'Payment',
      fields,
    })

    if (item?.backup?.length) {
      const process = await this.fetchData({
        query: { name: 'find', model: 'Process' },
        filter: { table_name: { _eq: 'payment' } },
      })
      const fileInfo = await this.fetchData({
        query: { name: 'find', model: 'FileParameter' },
        filter: { _and: [{ process: { table_name: { _eq: 'payment' } } }, { name: { _eq: 'spending_support' } }] },
      })
      const backup = {
        properties: {
          required: true,
          properties: {
            label: fileInfo[0].description,
            multiple: fileInfo[0].multiple,
            accept: fileInfo[0].fileType.mimes,
            fileTypeId: fileInfo[0].fileType.id,
            name: fileInfo[0].name,
            required: fileInfo[0].required,
          },
        },
      }
      await this.handleFileType(item?.backup, { properties: backup }, process[0].id, newPayment.id, fileInfo)
    }
  }

  async insertPayment (viewData, recipient) {
    const { formData: { expenses }, negotiation } = viewData
    const { paymentStatus: { pending } } = this

    const payments = expenses.payments.filter(payment => payment.person.id === recipient.person.id && !payment?.id)

    if (payments.length) {
      return Promise.all(payments.map(async payment => {
        const fields = {
          id_process_status: pending[0].id,
          id_payment_type: payment?.paymentType?.id,
          id_payment_recipient: recipient.id,
          amount: payment?.amount,
          id_deal: negotiation?.inspection.appraisal.deal.id,
          id_recipient_account: payment?.account?.id || null,
        }
        await this.pushData({
          model: 'Payment',
          fields,
        })
      }))
    }
  }

  async removeRecipient (item) {
    await this.removeData({
      model: 'PaymentRecipient',
      fields: { id: item.id },
    })
  }

  findLocalPos (recipient, val) {
    if (recipient?.id) return false
    return recipient?.person?.id === val?.beneficiary?.id &&
      recipient.payments.find(payment =>
        (payment?.recipientAccount?.id && val?.account?.id && payment?.recipientAccount?.id === val?.account?.id) ||
        (payment?.type?.id && val?.paymentType?.name === 'ok_view' && payment?.type?.id === val?.paymentType?.id) ||
        (payment?.amount === val?.amount)
      )
  }

  findLocalPayment (payment, person, type, val) {
    return !payment.id && type?.name === val?.type &&
      ((val?.account?.id && payment?.recipientAccount?.id === val?.account?.id) ||
        (val?.paymentType?.id && payment?.type?.id === val?.paymentType?.id) ||
        (val?.paymentRecipient?.person?.id === person.id && val?.amount === payment?.amount))
  }
}
